body { background-color: #ffffff; }
body { color: var(--clr-16442); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: var(--clr-16446);
font-family: 'Montserrat';
font-weight: 500;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 28px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: var(--clr-16446);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 28px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: var(--clr-16445);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: var(--clr-16445);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 28px;

}
}
h5 {
color: var(--clr-16445);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: var(--clr-16445);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 40px;

@media #{$large-up} {
font-size: 60px;

}
}
.button {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
.me-Quote .quote-body, .me-HtmlText blockquote {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
@media #{$large-up} {
font-size: 24px;

}
}
.me-Quote .quote-author {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul {
color: var(--clr-16445);
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 15;
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
summary {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 26px;

@media #{$medium-up} {
font-size: 28px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
nav.me-RespMenu.responsive-menu a {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
body {
font-family: 'Montserrat';
font-weight: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-16443);}
a:hover {color: var(--clr-16444);}
/* Secondary:2 */
.MES2 {
background-color: var(--clr-16447);
color: #ffffff;
 }
/* Secondary:3 */
.MES3 {
background-color: var(--clr-16447);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-16447);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Primary:4 */
.MES4 {
background-color: var(--clr-16445);
color: #ffffff;
 }
/* Primary:5 */
.MES5 {
background-color: var(--clr-16445);
color: #ffffff;
 }
.MES5 {
background-color: var(--clr-16445);
color: #ffffff;
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: #ffffff;
 }
 }
cite.MEC5{
color: #ffffff;
}
/* footer line:6 */
.MES6 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-16448) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Main Menu:7 */
nav.me-Menu.MES7 {
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: #ffffff;
text-transform: capitalize;
}
 &:hover > a.MEC7{color: var(--clr-16447);
}
 }
&.horizontal > .menu-item.MEC7 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC7 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC7 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC7 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

@media #{$medium-up} {
& > .menu-item > a{padding: 20px;}

}
& .sub-menu .menu-item a{padding: 20px;}


& > .menu-item.MEC7.active { & > a{ color: var(--clr-16447);}
 }
&.horizontal .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-16446-flat);}}
&.vertical .menu-item.MEC7:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-16446-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC7{background-color: var(--clr-16446); &:hover {background-color: 3;}
}
& .menu-item.MEC7, & .menu-item.MEC7 > div.MEC7{ & > a.MEC7{color: #ffffff;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 20.8px;
}}
 &:hover > a.MEC7{color: 2;
}
 }

}
}
 }
/* Light Grey Panel:8 */
.MES8 {
background-color: var(--clr-16449);
color: var(--clr-16450);
 }
.MES8 {
background-color: var(--clr-16449);
color: var(--clr-16450);
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: var(--clr-16445);
 }
 }
cite.MEC8{
color: var(--clr-16450);
}
/* Home Slider Panel:9 */
.MES9 {
border-radius: 40px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-16448);
 }
.MES9 {
border-radius: 40px;
-webkit-transform: translateZ(0);
overflow: hidden;
border-width: 1px;
border-style: solid;
border-color: var(--clr-16448);
 }
/* Box light grey:10 */
.MES10 {
background-color: var(--clr-16449);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-16451);
 }
.MES10 {
background-color: var(--clr-16449);
padding: 15px;

border-width: 1px;
border-style: solid;
border-color: var(--clr-16451);
 }
/* Shade 1:11 */
.MES11 {
background-color: var(--clr-16451);
color: var(--clr-16442);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-16451);
color: var(--clr-16442);
 }
.MES12 {
background-color: var(--clr-16451);
color: var(--clr-16442);
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: var(--clr-16442);
 }
 }
cite.MEC12{
color: var(--clr-16442);
}
/* Shade 2:13 */
.MES13 {
background-color: var(--clr-16446);
color: #ffffff;
 }
/* Shade 2:14 */
.MES14 {
background-color: var(--clr-16446);
color: #ffffff;
 }
.MES14 {
background-color: var(--clr-16446);
color: #ffffff;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
cite.MEC14{
color: #ffffff;
}
/* Alternate:15 */
.MES15 {
background-color: var(--clr-16443);
color: #ffffff;
 }
/* Alternate:16 */
.MES16 {
background-color: var(--clr-16443);
color: #ffffff;
 }
.MES16 {
background-color: var(--clr-16443);
color: #ffffff;
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: #ffffff;
 }
 }
cite.MEC16{
color: #ffffff;
}
/* White Panel:17 */
.MES17 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-16452);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: var(--clr-16450);
 }
.MES17 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-16452);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: var(--clr-16450);
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: var(--clr-16445);
 }
 }
cite.MEC17{
color: var(--clr-16450);
}
/* Bottom footer:18 */
.MES18 {
background-color: var(--clr-16443);
color: #ffffff;
font-size: 12.8px;
padding: 10px 0;

 }
.MES18 {
background-color: var(--clr-16443);
color: #ffffff;
font-size: 12.8px;
padding: 10px 0;

h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: var(--clr-16443);
 }
 }
cite.MEC18{
color: #ffffff;
font-size: 12.8px;
}
/* 2020 Dark Blue:19 */
.MES19 {
background-color: var(--clr-16446);
color: #ffffff;
padding: 10px 0;

@media #{$large-up} {
padding: 0;

}
 }
.MES19 {
background-color: var(--clr-16446);
color: #ffffff;
padding: 10px 0;

@media #{$large-up} {
padding: 0;

}
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: #ffffff;
 }
 }
cite.MEC19{
color: #ffffff;
}
/* Red Button:20 */
.MES20 {
background-color: var(--clr-16453);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
 }
.MES20 {
background-color: var(--clr-16453);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: #ffffff;
 }
 }
cite.MEC20{
color: #ffffff;
}
/* Yellow Button:21 */
.MES21 {
background-color: var(--clr-16454);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
 }
.MES21 {
background-color: var(--clr-16454);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: #ffffff;
 }
 }
cite.MEC21{
color: #ffffff;
}
/* Blue Button:22 */
.MES22 {
background-color: var(--clr-16445);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
 }
.MES22 {
background-color: var(--clr-16445);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
h1.MEC22, h2.MEC22, h3.MEC22, h4.MEC22, h5.MEC22, h6.MEC22 { color: #ffffff;
 }
 }
cite.MEC22{
color: #ffffff;
}
/* Fitle:23 */
.MES23 {
background-color: var(--clr-16448);
&:hover {background-color: var(--clr-16446);}
color: var(--clr-16450);
&:hover { color: #ffffff;}
padding: 10px 20px;

 }
/* Accordion:24 */
details.MES24 {
& > summary{background-color: var(--clr-16448);
}
color: var(--clr-16442);
& > article { color: var(--clr-16442);
 }
 }
/* Accordion:25 */
details.MES25 {
& > summary{background-color: var(--clr-16448);
}
color: var(--clr-16450);
& > article { color: var(--clr-16450);
 }
& > summary{padding: 10px;}

& > summary {& > div  > i{color: var(--clr-16443);
font-size: 14px;
}}
& > article a { color: var(--clr-16443);
 }
 }
/* Responsive Menu:27 */
nav.responsive-menu {
.menu-item.MEC27{background-color: var(--clr-16454);}
& .menu-item.MEC27, & .menu-item.MEC27 > div.menu-item-wrap{ & > a.MEC27, & > i{color: #ffffff;
font-size: 20.8px;
text-align: left;
}
  }
& .menu-item.MEC27 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 10px;}

& .sub-menu{.menu-item.MEC27{background-color: 21;}
& .menu-item.MEC27, & .menu-item.MEC27 > div.menu-item-wrap{ & > a.MEC27, & > i{color: 2;
text-align: left;
}
  }
 .sub-menu {}}

 }
/* News Right Menu:28 */
.MES28 {
background-color: var(--clr-16445);
color: #ffffff;
 }
.MES28 {
background-color: var(--clr-16445);
color: #ffffff;
h1.MEC28, h2.MEC28, h3.MEC28, h4.MEC28, h5.MEC28, h6.MEC28 { color: #ffffff;
 }
 }
a.MEC28 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC28{
color: #ffffff;
}
/* Back Button:29 */
.MES29 {
background-color: transparent;
&:hover {background-color: transparent;}
color: var(--clr-16443);
&:hover { color: var(--clr-16445);}
 }
/* Footer Menu:31 */
nav.me-Menu.MES31 {
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: #ffffff;
text-transform: capitalize;
}
 &:hover > a.MEC31{color: var(--clr-16445);
}
 }
&.horizontal > .menu-item.MEC31 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC31 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC31 .menu-item { border:0;
border-bottom-width: 1px;
border-color: 2;
border-style: dotted;
 }
&.horizontal > .menu-item.MEC31 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 5px;}

& .sub-menu .menu-item a{padding: 5px;}


& > .menu-item.MEC31.active { & > a{ color: var(--clr-16445);}
 }
&.horizontal .menu-item.MEC31:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-16447-flat);}}
&.vertical .menu-item.MEC31:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-16447-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC31{background-color: var(--clr-16447); &:hover {background-color: 21;}
}
& .menu-item.MEC31, & .menu-item.MEC31 > div.MEC31{ & > a.MEC31{color: #ffffff;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 20.8px;
}}
 &:hover > a.MEC31{color: 2;
}
 }

}
}
 }
/* White with red hover Panel:30 */
.MES30 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-16455);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: var(--clr-16450);
 }
.MES30 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-16455);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: var(--clr-16450);
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: var(--clr-16445);
 }
 }
cite.MEC30{
color: var(--clr-16450);
}
/* Shade 3:32 */
.MES32 {
background-color: var(--clr-16456);
color: #ffffff;
 }
/* Shade 3:33 */
.MES33 {
background-color: var(--clr-16456);
color: #ffffff;
 }
.MES33 {
background-color: var(--clr-16456);
color: #ffffff;
h1.MEC33, h2.MEC33, h3.MEC33, h4.MEC33, h5.MEC33, h6.MEC33 { color: #ffffff;
 }
 }
cite.MEC33{
color: #ffffff;
}
/* Light:34 */
.MES34 {
background-color: var(--clr-16454);
color: #ffffff;
 }
/* Light:35 */
.MES35 {
background-color: var(--clr-16454);
color: #ffffff;
 }
.MES35 {
background-color: var(--clr-16454);
color: #ffffff;
h1.MEC35, h2.MEC35, h3.MEC35, h4.MEC35, h5.MEC35, h6.MEC35 { color: #ffffff;
 }
 }
cite.MEC35{
color: #ffffff;
}
/* Box light grey 2 no padding:36 */
.MES36 {
background-color: var(--clr-16449);
border-width: 1px;
border-style: solid;
border-color: var(--clr-16451);
 }
.MES36 {
background-color: var(--clr-16449);
border-width: 1px;
border-style: solid;
border-color: var(--clr-16451);
 }
/* Slider Panel:37 */
.MES37 {
color: #ffffff;
 }
.MES37 {
color: #ffffff;
h1.MEC37, h2.MEC37, h3.MEC37, h4.MEC37, h5.MEC37, h6.MEC37 { color: var(--clr-16449);
 }
 }
cite.MEC37{
color: #ffffff;
}
/* :38 */
.MES38 {
background-color: var(--clr-16457);
 }
.MES38 {
background-color: var(--clr-16457);
 }
/* Document List:39 */
details.MES39 {
& > summary{background-color: var(--clr-16449);
}
& > article{padding: 5px;}

& > article {border-width: 2px;
border-style: solid;
border-color: var(--clr-16449);
}& > summary { font-size:18.2px;
@media #{$medium-up} {
font-size:19.6px;
}
 }
 }
/* White Panel no hover:40 */
.MES40 {
background-color: #ffffff;
color: var(--clr-16450);
 }
.MES40 {
background-color: #ffffff;
color: var(--clr-16450);
h1.MEC40, h2.MEC40, h3.MEC40, h4.MEC40, h5.MEC40, h6.MEC40 { color: var(--clr-16445);
 }
 }
cite.MEC40{
color: var(--clr-16450);
}
/* White Panel no hover 2:41 */
.MES41 {
background-color: #ffffff;
color: var(--clr-16450);
 }
.MES41 {
background-color: #ffffff;
color: var(--clr-16450);
h1.MEC41, h2.MEC41, h3.MEC41, h4.MEC41, h5.MEC41, h6.MEC41 { color: var(--clr-16445);
 }
 }
cite.MEC41{
color: var(--clr-16450);
}
/* New Yellow Button (Blue 2020):42 */
.MES42 {
background-color: var(--clr-16446);
&:hover, &.hover { background-color: var(--clr-16456);}
color: #ffffff;
 }
.MES42 {
background-color: var(--clr-16446);
&:hover, &.hover { background-color: var(--clr-16456);}
color: #ffffff;
h1.MEC42, h2.MEC42, h3.MEC42, h4.MEC42, h5.MEC42, h6.MEC42 { color: #ffffff;
 }
 }
cite.MEC42{
color: #ffffff;
}
/* New Yellow Button (Blue 2020):43 */
.MES43 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16447);}
color: #ffffff;
 }
.MES43 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16447);}
color: #ffffff;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
cite.MEC43{
color: #ffffff;
}
/* New Yellow Button (Gold 2020):44 */
.MES44 {
background-color: var(--clr-16447);
&:hover, &.hover { background-color: var(--clr-16453);}
color: #ffffff;
 }
.MES44 {
background-color: var(--clr-16447);
&:hover, &.hover { background-color: var(--clr-16453);}
color: #ffffff;
h1.MEC44, h2.MEC44, h3.MEC44, h4.MEC44, h5.MEC44, h6.MEC44 { color: #ffffff;
 }
 }
cite.MEC44{
color: #ffffff;
}
/* Gold 2020:45 */
.MES45 {
background-color: var(--clr-16447);
color: #ffffff;
 }
.MES45 {
background-color: var(--clr-16447);
color: #ffffff;
h1.MEC45, h2.MEC45, h3.MEC45, h4.MEC45, h5.MEC45, h6.MEC45 { color: #ffffff;
 }
 }
cite.MEC45{
color: #ffffff;
}
/* Tea Parallax:46 */
.MES46 {
background-color: var(--clr-16449);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://jch.unimelb.edu.au/img/12929/3177');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-16450);
 }
.MES46 {
background-color: var(--clr-16449);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://jch.unimelb.edu.au/img/12929/3177');
background-position: center center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-16450);
h1.MEC46, h2.MEC46, h3.MEC46, h4.MEC46, h5.MEC46, h6.MEC46 { color: var(--clr-16445);
 }
 }
cite.MEC46{
color: var(--clr-16450);
}
/* Tea Parallax:47 */
.MES47 {
background-color: var(--clr-16449);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://jch.unimelb.edu.au/img/12578/3094');
background-position: bottom center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-16450);
 }
.MES47 {
background-color: var(--clr-16449);
& > .underlay, & > .inner-overlay { opacity: 0.9999 }
& {overflow: hidden; }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://jch.unimelb.edu.au/img/12578/3094');
background-position: bottom center;
background-repeat: no-repeat;
top: -5%;
height: 130%;
background-size: cover;
min-height: 0.1px;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: var(--clr-16450);
h1.MEC47, h2.MEC47, h3.MEC47, h4.MEC47, h5.MEC47, h6.MEC47 { color: var(--clr-16445);
 }
 }
cite.MEC47{
color: var(--clr-16450);
}
/* Red w. grey:48 */
.MES48 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16451);}
color: #ffffff;
 }
.MES48 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16451);}
color: #ffffff;
h1.MEC48, h2.MEC48, h3.MEC48, h4.MEC48, h5.MEC48, h6.MEC48 { color: #ffffff;
 }
 }
cite.MEC48{
color: #ffffff;
}
/* New Yellow Button (Blue 2020):49 */
.MES49 {
background-color: var(--clr-16446);
&:hover, &.hover { background-color: var(--clr-16447);}
color: #ffffff;
 }
.MES49 {
background-color: var(--clr-16446);
&:hover, &.hover { background-color: var(--clr-16447);}
color: #ffffff;
h1.MEC49, h2.MEC49, h3.MEC49, h4.MEC49, h5.MEC49, h6.MEC49 { color: #ffffff;
 }
 }
cite.MEC49{
color: #ffffff;
}
/* Popup Close Button:50 */
.MES50 {
background-color: transparent;
color: var(--clr-16450);
&:hover { color: var(--clr-16445);}
font-size: 32px;
padding: 0;

 }
/* White Panel:51 */
.MES51 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-16452);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: var(--clr-16443);
 }
.MES51 {
background-color: #ffffff;
& > .overlay, & > .panel-link { 
  &:hover, &.hover {background-color: var(--clr-16452);}}
& > .overlay {
position: absolute;
top:0;
bottom:0;
left: 0;
right: 0;
z-index: 1;
}
color: var(--clr-16443);
h1.MEC51, h2.MEC51, h3.MEC51, h4.MEC51, h5.MEC51, h6.MEC51 { color: var(--clr-16445);
 }
 }
cite.MEC51{
color: var(--clr-16443);
}
/* Blue Hover 21:52 */
.MES52 {
background-color: var(--clr-16446);
&:hover, &.hover { background-color: var(--clr-16458);}
color: #ffffff;
 }
.MES52 {
background-color: var(--clr-16446);
&:hover, &.hover { background-color: var(--clr-16458);}
color: #ffffff;
h1.MEC52, h2.MEC52, h3.MEC52, h4.MEC52, h5.MEC52, h6.MEC52 { color: #ffffff;
 }
 }
cite.MEC52{
color: #ffffff;
}
/* green button:53 */
.MES53 {
background-color: var(--clr-16446);
&:hover {background-color: var(--clr-16453);}
color: #ffffff;
 }
/* red light test:54 */
.MES54 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
 }
.MES54 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
 }
cite.MEC54{
color: #ffffff;
}
/* red blue test 21:55 */
.MES55 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
 }
.MES55 {
background-color: var(--clr-16443);
&:hover, &.hover { background-color: var(--clr-16446);}
color: #ffffff;
h1.MEC55, h2.MEC55, h3.MEC55, h4.MEC55, h5.MEC55, h6.MEC55 { color: #ffffff;
 }
 }
cite.MEC55{
color: #ffffff;
}
/* :56 */
.me-block > .field-wrap.MES56 { 
& label { color: var(--clr-16443) !important; }
 }
